import React from 'react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  menuIsOpen: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_MENU':
      state.menuIsOpen
        ? enableBodyScroll(document.documentElement)
        : disableBodyScroll(document.documentElement)
      return {
        ...state,
        menuIsOpen: !state.menuIsOpen,
      }
    default:
      throw new Error('Bad action type')
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider

// https://codeburst.io/global-state-with-react-hooks-and-context-api-87019cc4f2cf
